.App {
  text-align: center;
}

.App-header {
  
}
/* -------- navigation styles ------------ */
.nav-bar h4:hover {
  cursor: pointer;
}
/* component show styles */
.thumbnail-container {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  /* width: 100vw; */
  margin-top: 5%;
  justify-content: center;
}
.thumbnail-container-2nd-row {
  display: flex;
  width: 100vw;
  /* margin-top: 5%; */
  justify-content: center;
}
.thumbnail-container-2nd-row img {
  width: 100%;
}
.thumbnail-container img{
  width: 100%;
}
/* ----------- component page styles ---------- */
.show-page {
  width: 90%;
  padding: 3%;
  margin: auto;
  text-align: center;
  max-width: 1000px;

}
.description {
  text-align: left;
  padding-left: 5%;
}
.description h2 {
  text-align: left;
  color: #205063;
  font-family: 'Lato', sans-serif;
  margin-bottom: 2.5%;
}
.description a {
  color: #205063;
  margin-top: 10px;
  font-size: 1rem;
}
.description p {
  font-size: 1rem;
  padding-right: 8%;
  font-family: 'Lato', sans-serif;
  text-align: left;
}
.img-container img{
  max-width: 90%;
  margin-bottom: 15px;
  border: solid #e0e0e0 1px;
  text-align: center;
}
#signage {
  padding-left: 5%;
}
.LV-img-container {
  text-align: center;
}
.LV-img-container img {
  max-width: 85%;
  margin-bottom: 15px;
}
.img-container {
  margin-top: 8%;
  text-align: center;
}
.thumbnail img:hover {
  background-color: rgba(0,0,0,.5);
}
.arrow {
  max-width: 12px;
  margin-left: 18px;
  margin-bottom: 2px;
}


/* modal styles */
.modal-header {
  color: #205063;
  padding-left: 5%;
}
.modal-body {
  padding-left: 5%;
  padding-right: 6%;
}
.modal-description a{
  color: #205063;
  line-height: 2.5;
}
.modal-description a:hover{
  color: #3686a5;
  line-height: 2.5;
}
.space {
  margin-bottom: 10%;
}
/* contact modal */
#contact-info {
  padding: 2% 0 0 4%;
}
#contact-info a {
  line-height: 3;
  font-size: 1rem;
}
#contact-info p {
  line-height: 2;
}

/* media query for small screens */
@media only screen and (max-width: 600px) {

  .modal-body img {
      width: 90vw;
  }
  .modal-dialog {
    margin-right: 0;
    }

  .thumbnail-container {
    display: block;
  }
  .modal-description {
    padding-right: 0
  }
  .modal-title h2 {
    text-align: left;
    padding-left: 10px;
  }
  .modal-body .img-container {
    padding-right: 0;
  }
  #resume-container {
    margin-left: 0;
}
}

